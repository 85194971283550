import api from '../../api';
const moduleResults = {
  namespaced: true,
  state: {
    results: [],
    filters: {
      isIncentiveOnly: false,
      isConnectedCommunityOnly: false,
      selectedBenefits: [],
      selectedLevel: [],
      selectedSize: [],
      selectedWorkType: [],
      selectedPoints: [],
      selectedAllowance: [],
      selectedType: [],
      searchValue: '',
    },
  },
  getters: {
    results: (state) => {
      return state.results;
    },
    filter: (state) => state.filters,
  },
  mutations: {
    SET_RESULTS(state, result) {
      state.results = result;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
  },
  actions: {
    fetchResults({ commit, rootState }, formValues) {
      // console.log(formValues);
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      api.results
        .fetchResults(formValues)
        .then((response) => {
          commit('SET_RESULTS', response);
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true });
          }
        });
    },
    resetResults({ commit }) {
      commit('SET_RESULTS', []);
    },
    updateFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
  },
};
export default moduleResults;
