import schoolBenefitsList from '@/api/data/results';
import { calculateBenefitsAndAllowances } from '@/api/modules/rulesEngine';

const fetchResults = async (formValues) => {
  const sortedList = schoolBenefitsList.sort((a, b) => {
    return a.schoolFullName.localeCompare(b.schoolFullName);
  });
  return calculateBenefitsAndAllowances(sortedList, formValues);
};

export default {
  fetchResults,
};
