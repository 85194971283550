var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isDontShowAgain)?_c('v-card',[_c('Dialog',{attrs:{"displayCloseBtn":true,"openDialog":_vm.showPopup,"icon":"info_outline","title":"Disclaimer","width":"550px"},on:{"close":_vm.handleDialogReturn},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"pl-6 pr-6 d-flex flex-column"},[_c('div',[_vm._v(" The benefits and allowances calculator requires entry of some personal information. This information is not retained or tracked. ")]),_c('div',{staticClass:"pt-5"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Please note")]),_vm._v(" that the results provided by the calculator are indicative only. ")]),_c('div',{staticClass:"pt-5 d-flex justify-end align-center"},[_c('span',[_c('v-checkbox',{staticClass:"pr-5",attrs:{"label":"Don't show again"},on:{"change":() => {
                    _vm.$gtm.trackEvent({
                      event: 'dont_show_again_selected',
                      item_category: 'disclaimer',
                      action: 'checkbox_click',
                      label:
                        _vm.setDontShowAgain == true ? 'Checked' : 'Unchecked',
                    });
                  }},model:{value:(_vm.setDontShowAgain),callback:function ($$v) {_vm.setDontShowAgain=$$v},expression:"setDontShowAgain"}})],1),_c('span',[_c('AdsButton',{attrs:{"primary":""},on:{"click":_vm.handleDialogReturn}},[_vm._v(" Close ")])],1)])])]},proxy:true}],null,false,1789685102)})],1):_vm._e(),(_vm.showInfoWindow)?_c('v-card',[_c('Dialog',{attrs:{"displayCloseBtn":true,"fullscreen":_vm.mobile,"openDialog":_vm.showInfoWindow,"maxWidth":"1150px","title":"School information","returnHandler":_vm.handleDialogReturn},on:{"close":function($event){_vm.showInfoWindow = false}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"mt-3 pa-3"},[_c('InfoWindow',{attrs:{"contents":_vm.modalInfo}})],1)]},proxy:true}],null,false,1809233755)})],1):_vm._e(),(!_vm.mobile)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',[_c('aside',{staticStyle:{"min-width":"475px"},style:({ width: _vm.expanded ? '850px' : '475px' })},[_c('SearchResults',{attrs:{"contentWidth":_vm.expanded ? '850px' : '475px',"results":_vm.results}})],1)]),_c('div',{staticClass:"grow"},[(_vm.isUserSearch)?_c('div',{staticClass:"expandLeft ml-8 mt-6"},[_c('v-btn',{staticStyle:{"width":"48px","height":"48px"},attrs:{"icon":""},on:{"click":_vm.expandResults}},[(!_vm.expanded)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" chevron_right ")]):_vm._e(),(_vm.expanded)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" chevron_left ")]):_vm._e()],1)],1):_vm._e(),_c('MapView',{on:{"showModalInfo":_vm.showInfo}})],1)]):(_vm.mobile)?_c('div',{staticStyle:{"width":"100vw"}},[_c('div',{style:(`${_vm.mobile ? 'width: 100vw' : ''}`)},[_c('SearchResults',{attrs:{"results":_vm.results,"tabs":_vm.mobile},scopedSlots:_vm._u([{key:"mobile",fn:function(){return [_c('MapView',{attrs:{"mapHeight":_vm.getMapHeight},on:{"showModalInfo":_vm.showInfo}})]},proxy:true}])})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }