const increase = 1.04;

export const dataChange18102023 = (data) => {
  return data.map((item) => {
    item.misoAllowances = item.misoAllowances.map((misoAllowance) => {
      switch (misoAllowance.allowanceDescription) {
        case 'Aboriginal Student Liaison Off':
        case 'Demonstration lesson (per 30 min)':
        case 'Demonstration lesson (per 40 min)':
        case 'Teachers of students with disabilities':
        case 'Home School Liaison':
        case 'Supervisor Of Girls Up  To 200':
        case 'Supervisor Of Girls 201 To 400':
        case 'Supervisor Of Girls 401 To 500':
        case 'Principal of school for students with disabilities':
        case 'Central Sch Assist Principal':
        case 'Central Sch Deputy Principal (Prim)':
        case 'Residential Agricultural High Principal On Call':
        case 'Residential Agricultural High Deputy Principal On Call':
        case 'Residential Agricultural High Head Teacher Residential Supervision':
        case 'Residential Agricultural High Rostered Supervision teacher':
        case 'Residential Agricultural High Teacher In Charge of Residential Supervision':
        case 'Dem. Sch. Principal (PP1)':
        case 'Dem. Sch. Principal (PP2)':
        case 'Dem. Sch. Promotion Positions':
        case 'Demonstration School Teacher':
        case 'Demonstration (per lesson)':
        case 'Stewart House Principal':
          misoAllowance.value = Math.round(misoAllowance.value * increase);
          return misoAllowance;
        default:
          return misoAllowance;
      }
    });
    item.otherAllowances = item.otherAllowances?.map((otherAllowance) => {
      switch (otherAllowance.allowanceDescription) {
        case 'Year Advisor':
          otherAllowance.value = Math.round(otherAllowance.value * increase);
          break;
        default:
          break;
      }
      return otherAllowance;
    });
    item.conditionalAllowances = item.conditionalAllowances?.map((conditionalAllowance) => {
      switch (conditionalAllowance.allowanceDescription) {
        case 'Isolation from goods and service allowance':
        case 'Cold Climatic allowance':
        case 'Hot Climatic allowance':
          conditionalAllowance.value = Math.round(conditionalAllowance.value * increase);
          break;
        default:
          break;
      }
      return conditionalAllowance;
    });

    return item;
  });
};
