import { IeBlocker } from '@nswdoe/doe-ui-core';
import Vue from 'vue';
import JsonCSV from 'vue-json-csv';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import api from './api';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin';
import { vuegtm } from './plugins/vuegtm';

// Enhance the event system on Vue.prototype
import { useDownwardBroadcast, useEventHub, useUpwardBroadcast } from './hooks/events';

useEventHub(Vue);
useUpwardBroadcast(Vue);
useDownwardBroadcast(Vue);

// import VueCompositionAPI from '@vue/composition-api';
// Vue.use(VueCompositionAPI);

Vue.mixin(titleMixin);
let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'Benefits Calculator',
    },
  };
} else {
  // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App;
  renderProps = {};

  Vue.config.productionTip = false;

  /**
   * Unified interface for API requests
   * Added onto Vue.prototype object, so all Vue component instances can access it via `this.$api`
   */
  Vue.prototype.$api = api;
}

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
  },
  installComponents: true,
});

vuegtm(Vue);

Vue.component('DownloadCsv', JsonCSV);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(renderComponent, renderProps),
}).$mount('#app');
