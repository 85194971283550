<template>
  <v-card
    v-if="contents"
    class="d-flex flex-wrap justify-space-between"
    flat>
    <div
      class="pr-4 pl-4 pb-3 grow"
      style="flex-basis: 40%">
      <GmapStreetViewPanorama
        :options="{
          addressControl: false,
          fullscreenControl: false,
          linksControl: false,
          // zoomControl: false,
          clickToGo: false,
          // panControl: false,
          // disableDefaultUI: true,
        }"
        :position="{ lat: contents.location.lat, lng: contents.location.lng }"
        :pov="{ heading: 70, pitch: 0 }"
        :style="` ${
          mobile ? 'width: 100%; height: 200px' : 'width: 410px; height: 200px'
        }`"
        :visible="true"
        :zoom="2" />
      <div class="d-flex flex-column mt-2 justify-space-between">
        <div
          :class="`pt-2 pl-0 default-text font-bold title-text ${
            mobile ? 'mobile-label' : ''
          }`"
          style="color: #002664">
          {{ contents.schoolFullName }}
        </div>
        <div
          v-if="
            contents.isIncentive || contents.connectedCommunitySchool === 'true'
          "
          class="d-flex flex-column pt-1">
          <div
            v-if="contents.isIncentive"
            class="align-start">
            <Chip
              :color="COLOURS.INCENTIVE.background"
              small
              text="INCENTIVE SCHOOL"
              textColor="primary">
              <span
                :class="`${
                  mobile ? 'mobile-text' : 'default-text'
                } font-weight-bold`"
                style="color: #ffffff">
                INCENTIVE SCHOOL
              </span>
            </Chip>
          </div>
          <div
            v-if="contents.connectedCommunitySchool === 'true'"
            class="align-start pt-1">
            <Chip
              v-if="contents.connectedCommunitySchool === 'true'"
              :color="COLOURS.CC.background"
              small
              text="CC SCHOOL"
              textColor="primary">
              <span
                :class="`${
                  mobile ? 'mobile-text' : 'default-text'
                } font-weight-bold`"
                style="color: #000000">
                CONNECTED COMMUNITY SCHOOL
              </span>
            </Chip>
          </div>
        </div>
      </div>
      <div class="d-flex text-left align-center pt-2 pb-0 pl-0">
        <div
          :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`"
          style="width: 40%">
          School code:
        </div>
        <div :class="`pl-5 ${mobile ? 'mobile-text' : 'default-text'}`">
          {{ contents.schoolCode }}
        </div>
      </div>
      <div class="d-flex text-left align-center pt-2 pb-0 pl-0">
        <div
          :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`"
          style="width: 40%">
          Total enrolments:
        </div>
        <div :class="`pl-5 ${mobile ? 'mobile-text' : 'default-text'}`">
          {{ contents.studentNumber }}
        </div>
      </div>
      <v-divider class="ma-4"></v-divider>
      <div class="d-flex text-left pt-2 pl-0">
        <div>
          <v-btn
            icon
            small
            style="background: #ebebeb">
            <v-icon
              class="material-icons-outlined"
              color="#407EC9"
              small>
              place
            </v-icon>
          </v-btn>
        </div>
        <div
          :class="`pl-3 align-self-center ${
            mobile ? 'mobile-label' : 'label-text'
          }`">
          {{ contents.address }}
          <a
            :href="`${googleMapsUrl}${
              contents.location.lat
                ? `${contents.location.lat},${contents.location.lng}`
                : contents.address
            }`">
            Get Directions
          </a>
        </div>
      </div>
      <div class="d-flex text-left pt-3 pl-0">
        <div>
          <v-btn
            icon
            small
            style="background: #ebebeb">
            <v-icon
              class="material-icons-outlined"
              color="#407EC9"
              small>
              phone
            </v-icon>
          </v-btn>
        </div>
        <div
          :class="`pl-3 align-self-center ${
            mobile ? 'mobile-label' : 'label-text'
          }`">
          <a :href="`tel:${contents.phone}`">{{ contents.phone }}</a>
        </div>
      </div>
      <div class="d-flex text-left pt-3 pl-0">
        <div>
          <v-btn
            icon
            small
            style="background: #ebebeb">
            <v-icon
              class="material-icons-outlined"
              color="#407EC9"
              small>
              email
            </v-icon>
          </v-btn>
        </div>
        <div
          :class="`pl-3 align-self-center ${
            mobile ? 'mobile-label' : 'label-text'
          }`">
          <a :href="`mailto:${contents.email}`">{{ contents.email }}</a>
        </div>
      </div>
    </div>
    <div
      :class="`pa-3 pl-4 ${mobile ? 'pt-5' : 'pt-0'}  grow`"
      style="flex-basis: 60%">
      <div class="d-flex text-left justify-content-between grow">
        <div class="flex-column grow">
          <div
            :class="`title`"
            style="color: #002664">
            Benefits and allowances
          </div>
          <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
            {{ contents.transferPoints }} transfer points
          </div>
        </div>
        <div
          :class="`px-5  ${
            mobile ? 'mobile-label text-right' : 'label-text align-self-start'
          }`">
          Total major benefits up to
          <div :class="`title text-right ${mobile ? 'mobile-label' : ''}`">
            ${{
              contents.benefitsAndAllowances.majorFinancialBenefits.total.toLocaleString()
            }}
          </div>
        </div>
      </div>
      <div class="mt-5">
        <ads-expansion-panel
          v-model="expansion"
          :items="[
            {
              title: 'Major financial benefits',
              content: 'panel text',
              id: 1,
              icon: '',
            },
            {
              title: 'Professional benefits',
              content: 'panel text',
              id: 2,
              icon: '',
            },
            {
              title: 'Available additional allowances',
              content: 'panel text',
              id: 3,
              icon: '',
            },
          ]">
          <template #content1>
            <div class="pa-1 px-3">
              <v-list-item
                class="d-flex justify-space-between font-weight-bold"
                style="color: #002664">
                <div
                  :class="`text-left ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '55%' : '65%'}`">
                  Benefit
                </div>
                <div
                  :class="`text-center ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '24%' : '14%'}`">
                  Included
                </div>
                <div
                  :class="`text-right ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: 21%`">
                  Value
                </div>
              </v-list-item>
              <v-divider />
              <v-list-item
                v-for="(benefitItem, index) in majorFinancialBenefits.benefits"
                :key="'majorFinancialBenefits_' + index"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  :style="`width: ${mobile ? '55%' : '65%'} `"
                  class="text-left">
                  <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                    {{ benefitItem.benefit }}
                  </div>
                  <div
                    v-if="benefitItem.extraLine"
                    :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`">
                    {{ benefitItem.extraLine }}
                  </div>
                </div>
                <div
                  :style="`width: ${mobile ? '24%' : '14%'}`"
                  class="text-center">
                  <v-icon
                    v-if="benefitItem.isIncluded"
                    color="#00AA45">
                    check
                  </v-icon>
                  <v-icon
                    v-else
                    color="#D7153A">
                    close
                  </v-icon>
                </div>
                <div
                  :style="`width: 21%`"
                  class="text-right d-flex flex-column flex-nowrap">
                  <div :class="`${mobile ? 'mobile-label' : ''}`">
                    <span
                      v-if="
                        benefitItem.benefit ===
                        'Rural and remote relocation support payment'
                      ">
                      up to
                    </span>
                    <span class="font-weight-bold">
                      {{
                        index === 1
                          ? `${benefitItem.value}%`
                          : `$${benefitItem.value.toLocaleString()}`
                      }}
                    </span>
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <div
                class="d-flex grow pa-4 pt-5"
                style="background: #f4f4f7">
                <div
                  :class="`text-left subtitle-1 grow total ${
                    mobile ? 'mobile-label' : ''
                  }`">
                  Total
                </div>
                <span
                  :class="`text-right grow default-text ${
                    mobile ? 'mobile-text' : ''
                  }`"
                  style="color: #495054">
                  up to
                  <span
                    :class="`subtitle-1 total ${mobile ? 'mobile-label' : ''}`">
                    ${{ majorFinancialBenefits.total.toLocaleString() }}
                  </span>
                </span>
              </div>
              <Alert
                backgroundColour
                class="pt-4 pl-4 pb-0 text-left"
                htmlText="<div class='font-weight-bold default-text'>As defined in the departmental policy</div><div class='font-weight-regular default-text'>For more information about major financial benefits, please visit <a href='https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives' target='_blank' class='font-weight-bold' style='text-decoration: none; color: #002664'>here</a></div>"
                inPage
                showAlert
                text="As defined in the departmental policy"
                type="info" />
            </div>
          </template>
          <template #content2>
            <div class="pa-1 px-3">
              <v-list-item
                class="d-flex justify-space-between font-weight-bold"
                style="color: #002664">
                <div
                  :class="`text-left ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '55%' : '65%'} `">
                  Benefit
                </div>
                <div
                  :class="`text-center ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '24%' : '14%'}`">
                  Included
                </div>
                <div
                  :class="`text-right ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: 21%`">
                  Value
                </div>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-for="(benefitItem, i) in professionalBenefits.benefits"
                :key="'professionalBenefits_' + i"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  :style="`width: ${mobile ? '55%' : '65%'}`"
                  class="d-flex flex-column align-content-space-around text-left">
                  <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                    {{ benefitItem.benefit }}
                  </div>
                  <div
                    v-if="benefitItem.extraLine"
                    :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`">
                    {{ benefitItem.extraLine }}
                  </div>
                </div>
                <div
                  :style="`width: ${mobile ? '24%' : '14%'}`"
                  class="text-center">
                  <v-icon
                    v-if="benefitItem.isIncluded"
                    color="#00AA45">
                    check
                  </v-icon>
                  <v-icon
                    v-else
                    color="#D7153A">
                    close
                  </v-icon>
                </div>
                <div
                  :style="`width: 21%`"
                  class="text-right d-flex flex-column flex-nowrap">
                  <div
                    :class="`font-weight-bold ${mobile ? 'mobile-label' : ''}`">
                    {{ benefitItem.value }}
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <Alert
                backgroundColour
                class="pt-4 pl-4 pb-0 text-left"
                htmlText="<div class='font-weight-bold default-text'>As defined in the departmental policy</div><div class='font-weight-regular default-text'>For more information about professional benefits, please visit <a href='https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives' target='_blank' class='font-weight-bold' style='text-decoration: none; color: #002664'>here</a></div>"
                inPage
                showAlert
                text="As defined in the departmental policy"
                type="info" />
            </div>
          </template>
          <template #content3>
            <div class="pa-1 px-3">
              <v-list-item
                class="d-flex justify-space-between font-weight-bold"
                style="color: #002664">
                <div
                  :class="`text-left ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '55%' : '65%'}`">
                  Benefit
                </div>
                <div
                  :class="`text-right ${mobile ? 'mobile-label' : ''}`"
                  :style="`width: ${mobile ? '45%' : '35%'}`">
                  Value
                </div>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-for="(benefitItem, i) in conditionalAllowances.benefits"
                :key="'conditionalAllowances_' + i"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  :style="`width: ${mobile ? '55%' : '65%'}`"
                  class="text-left">
                  <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                    {{ benefitItem.benefit }}
                  </div>
                  <div
                    v-if="benefitItem.extraLine"
                    :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`">
                    {{ benefitItem.extraLine }}
                  </div>
                </div>
                <div
                  :style="`width: ${mobile ? '45%' : '35%'}`"
                  class="text-right d-flex flex-column flex-nowrap">
                  <div
                    :class="`font-weight-bold ${mobile ? 'mobile-label' : ''}`">
                    {{
                      typeof benefitItem.value === "number"
                        ? `$${benefitItem.value.toLocaleString()}`
                        : benefitItem.value
                    }}
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                v-for="(benefitItem, i) in otherBenefits.benefits"
                :key="'otherBenefits' + i"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  :style="`width: ${mobile ? '55%' : '65%'}`"
                  class="text-left">
                  <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                    {{ benefitItem.benefit }}
                  </div>
                  <div
                    v-if="benefitItem.extraLine"
                    :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`">
                    {{ benefitItem.extraLine }}
                  </div>
                </div>
                <div
                  :style="`width: ${mobile ? '45%' : '35%'}`"
                  class="text-right d-flex flex-column flex-nowrap">
                  <div
                    :class="`font-weight-bold ${mobile ? 'mobile-label' : ''}`">
                    {{
                      typeof benefitItem.value === "number"
                        ? `$${benefitItem.value.toLocaleString()}`
                        : benefitItem.value
                    }}
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                v-for="(benefitItem, i) in misoAllowances.benefits"
                :key="'misoAllowances_' + i"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  :style="`width: ${mobile ? '55%' : '65%'}`"
                  class="text-left">
                  <div :class="`${mobile ? 'mobile-label' : 'label-text'}`">
                    {{ benefitItem.benefit }}
                  </div>
                  <div
                    v-if="benefitItem.extraLine"
                    :class="`pt-1 ${mobile ? 'mobile-text' : 'default-text'}`">
                    {{ benefitItem.extraLine }}
                  </div>
                </div>
                <div
                  :style="`width: ${mobile ? '45%' : '35%'}`"
                  class="text-right d-flex flex-column flex-nowrap">
                  <div
                    :class="`font-weight-bold ${mobile ? 'mobile-label' : ''}`">
                    {{
                      typeof benefitItem.value === "number"
                        ? `$${benefitItem.value.toLocaleString()}`
                        : benefitItem.value
                    }}
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    :class="`default-text ${mobile ? 'mobile-text' : ''}`"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                v-for="(benefitItem, i) in otherAllowances.benefits"
                :key="'otherAllowances_' + i"
                class="d-flex justify-space-between py-5"
                style="border-bottom: 1px solid #ebebeb">
                <div
                  class="text-left"
                  style="width: 75%">
                  {{ benefitItem.benefit }}
                  <br v-if="benefitItem.extraLine" />
                  {{ benefitItem.extraLine }}
                </div>
                <div
                  class="text-right d-flex flex-column flex-nowrap"
                  style="width: 25%">
                  <div class="font-weight-bold">
                    {{
                      typeof benefitItem.value === "number"
                        ? `$${benefitItem.value.toLocaleString()}`
                        : benefitItem.value
                    }}
                  </div>
                  <div
                    v-if="benefitItem.extraValueLine"
                    class="default-text"
                    style="color: #495054">
                    {{ benefitItem.extraValueLine }}
                  </div>
                </div>
              </v-list-item>
              <Alert
                backgroundColour
                class="pt-4 pl-4 pb-0 text-left"
                htmlText="<div class='font-weight-bold default-text'>Outlined in industrial agreements</div><div class='font-weight-regular default-text'>For more information about additional allowances, please visit <a href='https://education.nsw.gov.au/teach-nsw/find-teaching-jobs/choose-rural/benefits-and-incentives' class='font-weight-bold' target='_blank' style='text-decoration: none; color: #002664'>here</a>. Please note that some allowances may require teachers to complete a HR form. A full list of HR forms can be found <a href='https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/benefits-overview/employee-forms' target='_blank' class='font-weight-bold' style='text-decoration: none; color: #002664'>here</a></div>"
                inPage
                showAlert
                text="As defined in the departmental policy"
                type="info" />
            </div>
          </template>
        </ads-expansion-panel>
      </div>
    </div>
  </v-card>
</template>
<script>
import { AdsExpansionPanel, Alert, Chip } from "@nswdoe/doe-ui-core";
import { COLOURS } from "@/constants";
import { gmapApi as googleMapsApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
export default {
  name: "InfoWindow",
  components: {
    Chip,
    AdsExpansionPanel,
    Alert,
  },
  data() {
    return {
      COLOURS: COLOURS,
      expansion: 0,
      photo: "",
      googleMapsUrl: "https://www.google.com.au/maps/dir//",
      majorFinancialBenefits:
        this.contents.benefitsAndAllowances.majorFinancialBenefits,
      professionalBenefits:
        this.contents.benefitsAndAllowances.professionalBenefits,
      misoAllowances: this.contents.benefitsAndAllowances.misoAllowances ?? {
        benefits: [],
        total: 0,
      },
      otherAllowances: this.contents.benefitsAndAllowances.otherAllowances ?? {
        benefits: [],
        total: 0,
      },
      conditionalAllowances: this.contents.benefitsAndAllowances
        .conditionalAllowances ?? { benefits: [], total: 0 },
      otherBenefitsItems: [
        "Medical and Dental re-imbursement",
        "Motor Vehicle Depreciation Allowance",
        "Relocation Subsidy",
        "Vacation travel expense (no of vacation journeys)",
        "Extended summer vacation",
        "TEPS benefit for each 50 days of casual/temporary service",
      ],
      additionalAllowances: [
        {
          benefit: "10 week trial placement",
          extraLine: "(prior to permanent appointment)",
          isIncluded: true,
          value: "70%",
        },
        {
          benefit: "Additional personal leave days",
          isIncluded: true,
          value: "70%",
        },
        {
          benefit: "Additional professional development days",
          isIncluded: true,
          value: "70%",
        },
        {
          benefit: "Priority transfer",
          isIncluded: true,
          value: "70%",
        },
        {
          benefit: "Transferred Officers Compensation",
          extraLine: "(years of service required)",
          isIncluded: true,
          value: "70%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ map: "mapStore", googleApi: "getGoogle" }),
    google: googleMapsApi,
    streetViewImageUrl() {
      return `https://maps.googleapis.com/maps/api/streetview?size=480x167&location=${this.contents.location.lat},${this.contents.location.lng}&fov=80&heading=70&pitch=0&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
    },
    otherBenefits() {
      return this.contents.benefitsAndAllowances.otherBenefits
        ? {
            benefits:
              this.contents.benefitsAndAllowances.otherBenefits.benefits.filter(
                (result) =>
                  this.otherBenefitsItems.indexOf(result.benefit) !== -1
              ),
            total: this.contents.benefitsAndAllowances.otherBenefits.total,
          }
        : {
            benefits: [],
            total: 0,
          };
    },
    totalAllowances() {
      return (
        this.misoAllowances.total +
        this.otherAllowances.total +
        this.conditionalAllowances.total
      );
    },
    photoUrl() {
      this.getGoogleMapsPhoto();
      return this.photo;
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    contents: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.trackBenefitClick(0);
  },
  methods: {
    trackBenefitClick(value) {
      const eventName = [
        "Major financial benefits",
        "Professional benefits",
        "Available additional allowances",
      ];
      this.$gtm.trackEvent({
        event: "school_information",
        item_category: "section_view",
        section_viewed: eventName[value],
        action: "benefits_allowances_click",
      });
    },
    async getGoogleMapsPhoto() {
      const request = {
        query: this.contents.schoolFullName,
        fields: ["name", "photo", "geometry"],
      };
      const service = new this.google.maps.places.PlacesService(this.map);
      let photoUrl = "";
      const callBack = (results, status) => {
        if (
          status === "OK" &&
          results &&
          results.length > 0 &&
          results[0].photos &&
          results[0].photos.length > 0
        ) {
          this.photo = photoUrl;
        } else
          this.photo =
            "https://maps.gstatic.com/tactile/pane/default_geocode-1x.png";
      };
      await service.findPlaceFromQuery(request, callBack);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    addSum(benefits) {
      let sum = 0;
      benefits.forEach((item) => {
        if (typeof item.value !== "string") sum += item.value ?? 0;
      });
      return sum;
    },
  },
  watch: {
    expansion(value) {
      if (value >= 0 && value <= 2) {
        this.trackBenefitClick(value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.total {
  font-weight: bold;
  color: black;
  font-size: 20px !important;
}

mobile-total {
  font-size: 16px !important;
}

.default-text {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 12px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}

.font-bold {
  font-weight: bold !important;
}

#pano {
  //width: 100%;
  //height: 100%;
}
</style>
