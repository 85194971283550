<template>
  <!--  For each View component, follow Vuetify <v-container> => <v-row> => <v-col> grid system  -->
  <v-container :style="!isUserSearch ? 'border-left:1px solid #CDD3D6' : ''" fluid>
    <v-row class="ma-0">
      <v-col class="text-center pa-0">
        <div style="min-height: 300px; height: -webkit-calc(100vh - 118px)" class="grow pa-0">
          <GoogleMapLoader
            v-if="isUserSearch"
            :apiKey="apiKey"
            :mapConfig="mapConfig"
            :schools="results"
            :selected="showMarkerInfo"
          />
          <div v-if="!isUserSearch" class="flex-column">
            <div class="align-self-center" style="padding-top: 150px">
              <img alt="" src="../../../public/Illustration.png" />
            </div>
            <div class="pt-2 heading">Complete your employee details to see results</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GoogleMapLoader from './GoogleMapLoader';
import { mapGetters } from 'vuex';
import { SIZES } from '@/constants';

export default {
  name: 'MapView',
  components: {
    GoogleMapLoader,
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      showMarkerInfo: null,
      SIZES: SIZES,
      getMapHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      isUserSearch: 'isUserSearch',
      results: 'moduleResults/results',
      showMarkerClicked: 'showInfo',
    }),
    mapConfig() {
      return {
        center: {
          lat: -33.865143,
          lng: 148.2099,
        },
        zoom: this.mobile ? 5 : 7,
        minZoom: 1,
        maxZoom: 20,
        mapTypeControl: false,
        fullscreenControl: false,
      };
    },

    schoolResults() {
      return this.results;
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    bannerHeight() {
      return this.mobile && this.isUserSearch
        ? this.SIZES.BANNER.MINIMISED
        : this.mobile
        ? this.SIZES.BANNER.MOBILE
        : this.SIZES.BANNER.DESKTOP;
    },
  },
  methods: {
    showMarker(markerInfo) {
      this.showMarkerInfo = { ...markerInfo, dateTime: Date.now() };
      return true;
    },
  },
  props: {
    mapHeight: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  watch: {
    showMarkerClicked(markerObj) {
      if (markerObj.pan) {
        this.showMarker(markerObj.item);
      }
    },
    height(newVal) {
      this.getMapHeight = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

.ads-link {
  color: $ads-red-2-dark !important;
}

.info-window {
  position: absolute;
  width: 1000px;
  height: 80%;
  top: 15px;
  left: 0px;
}
</style>
