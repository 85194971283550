<template>
  <v-app
    class="d-flex flex-column"
    :class="mobile ? 'mobile' : ''">
    <div class="banner">
      <Banner
        contentClass="pl-3"
        defaultBackground1
        :iconSize="SIZES.BANNER_ICON.DESKTOP"
        icon="mdi-calculator">
        <template #title>
          <h1>Benefits Calculator</h1>
        </template>
        <template #subtitle>
          <p>
            Learn about the range of benefits and allowances for teachers and
            executives in NSW public schools.
          </p>
        </template>
      </Banner>
    </div>
    <div class="contents">
      <v-main>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </div>
    <!-- App-wide component -->
    <BlockUI v-if="$store.state.isLoading" />
  </v-app>
</template>

<script>
import { Banner, BlockUI } from "@nswdoe/doe-ui-core";
import { SIZES } from "@/constants";
export default {
  name: "App",
  title: "Benefits Calculator",
  components: {
    BlockUI,
    Banner,
  },
  data() {
    return {
      SIZES: SIZES,
      scrollerHeight: 0,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import "./scss/ads";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans&display=swap");
// example of overriding classes in `ads.scss`
.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  /*  a:not(.v-btn, .v-tab) {
      color: $ads-blue-1;

      &:visited {
        color: $ads-blue-1;
      }
    }*/
}

.mobile .col {
  padding: 4px !important;
}
.mobile .row {
  margin: 0 !important;
}
.banner .container {
  max-width: 100%;
}
.contents {
  height: calc(100vh - 118px);
}
.mobile .contents {
  height: calc(100vh - 160px);
}
.banner {
  height: auto;
  min-height: auto;
}
.mobile .banner {
  min-height: 160px;
  height: 160px;
}
.appSubtitle-mobile {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle-mobile {
  font-size: 25px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  margin: 0;
  vertical-align: top;
}

.container {
  padding: 0;

  .container {
    padding: 0;
  }
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}

.default-text {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
}

.label-text {
  font-size: 16px !important;
}

.title-text {
  font-size: 20px !important;
}

.font-bold {
  font-weight: bold !important;
}

.mobile-text {
  font-size: 12px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
