<template>
  <v-virtual-scroll
    :class="`${mobile ? 'mx-n0' : inFullScreen ? '' : 'mx-n5'} invisible-scrollbar`"
    :height="(parseInt(height) - 5).toString() + 'px'"
    :item-height="mobile ? '180px' : '180px'"
    :items="schoolResults"
    :max-width="mobile ? '100vw' : contentWidth"
    :style="`scrollbar-width: none; `"
    bench="5"
    three-line
  >
    <template #default="{ item }">
      <v-list-item
        :key="`schoolCode_${item.schoolCode}`"
        class="pa-6 pt-5 flex-column"
        :style="`border-bottom: 1px solid #ccc; height: ${mobile ? '180px' : '180px'}`"
      >
        <div class="d-flex justify-space-between" style="width: 100%; height: 100%">
          <div class="d-flex flex-column justify-space-between" style="height: 100%">
            <div>
              <div
                :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`"
                style="color: #002664; cursor: pointer"
                @click="showResult(item)"
              >
                {{ item.schoolFullName }}
              </div>
              <div
                v-if="item.address"
                :class="`${mobile ? 'mobile-text' : 'default-text'}`"
                style="word-break: break-word; color: #22272b"
              >
                {{ item.address }}
              </div>
            </div>
            <div>
              <div
                v-if="item.transferPoints"
                :class="`${mobile ? 'mobile-text' : 'default-text'} mt-2`"
                style="color: #22272b"
              >
                {{ item.transferPoints }} transfer points
              </div>
              <Chip
                v-if="item.isIncentive"
                :color="COLOURS.RENTAL_SUBSIDY.background"
                dense
                label
                small
                text="Rental subsidy available"
                textColor="primary"
                class="pa-1"
              >
                <span
                  :class="`${mobile ? 'mobile-text' : 'default-text pt-1'} ma-0 pa-0`"
                  style="font-weight: normal !important"
                >
                  Rental subsidy available
                </span>
              </Chip>
            </div>
          </div>
          <div class="text-right d-flex flex-column justify-space-between">
            <div>
              <div v-if="item.isIncentive || item.connectedCommunitySchool === 'true'">
                <div class="mb-2" v-if="item.isIncentive">
                  <Chip
                    :color="COLOURS.INCENTIVE.background"
                    :textColor="COLOURS.INCENTIVE.text"
                    small
                    text="INCENTIVE SCHOOL"
                  >
                    <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`">
                      INCENTIVE SCHOOL
                    </span>
                  </Chip>
                </div>
                <div class="mb-2" v-if="item.connectedCommunitySchool === 'true'">
                  <Chip :color="COLOURS.CC.background" :textColor="COLOURS.CC.text" small text="CC SCHOOL">
                    <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`"> CC SCHOOL </span>
                  </Chip>
                </div>
              </div>
              <div>
                <div class="default-text" style="color: #22272b">Total major benefits</div>
                <div :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`">
                  <span class="body-2" style="color: #22272b"> up to </span>
                  <span class="label-text">
                    {{ `$${item.benefitsAndAllowances.majorFinancialBenefits.total.toLocaleString()}` }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="grow text-right">
          <div class="mt-0">
            <AdsButton
              v-if="!compareList.find((school) => school.schoolCode === item.schoolCode)"
              icon="add_circle_outline"
              class="subtitle-1"
              :tertiary="true"
              :disabled="numberOfCompare >= 5"
              @click="addToCompare(item)"
              button-text="Add to compare"
              style="padding: 0"
            />
            <div v-if="compareList.find((school) => school.schoolCode === item.schoolCode)" class="d-flex">
              <span class="default-text .body-2 pr-2 grow align-self-center" style="color: #495054">
                Added to compare
              </span>
              <AdsButton
                :tertiary="true"
                class="font-weight-bold pr-0 removeSchool subtitle-2 primary--text"
                @click="remoteFromCompare(item)"
                button-text="Remove"
                style="padding: 0; padding-left: 2px"
              />
            </div>
          </div>
        </div>
      </v-list-item>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { Chip, AdsButton } from '@nswdoe/doe-ui-core';

import { COLOURS } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'VirtualScroller',
  components: {
    Chip,
    AdsButton,
  },
  props: {
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    contentWidth: {
      type: String,
      default: '475px',
    },
    height: {
      type: String,
      default: '0',
    },
    inFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  data() {
    return {
      COLOURS: COLOURS,
    };
  },
  computed: {
    ...mapGetters({
      numberOfCompare: 'numberOfCompare',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    schoolResults() {
      return this.results;
    },
    compareList() {
      return this.$store.state.compareList;
    },
  },
  methods: {
    showResult(item) {
      this.$gtm.trackEvent({
        event: 'school_detail_viewed',
        item_category: 'school',
        action: 'detail_popup_opened',
        school_name: item.schoolFullName,
        school_code: item.schoolCode,
        total_enrolment: item.studentNumber,
        benefits_upto: '$' + item.benefitsAndAllowances.majorFinancialBenefits.total.toLocaleString(),
        virtualUrl: location.href + 'showSchool',
      });

      item.location.lat
        ? this.$store.dispatch('setShowInfo', { pan: true, item })
        : this.$store.dispatch('setShowInfo', { pan: false, item });
    },
    addToCompare(school) {
      this.$gtm.trackEvent({
        event: 'add_to_compare_clicked',
        item_category: 'school',
        action: 'add_to_compare_button_click',
        school_name: school.schoolFullName,
        school_code: school.schoolCode,
      });

      this.$emit('compareSchool');
      this.$store.dispatch('addToCompare', school);
    },
    remoteFromCompare(school) {
      this.$gtm.trackEvent({
        event: 'school_removed_from_compare',
        item_category: 'school',
        action: 'compare_remove_click',
        school_name: school.schoolFullName,
        school_code: school.schoolCode,
      });

      this.$store.dispatch('removeFromCompare', school);
    },
  },
};
</script>

<style scoped>
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
.removeSchool::v-deep.v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
  padding: 0 !important;
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 14px !important;
}

.label-text {
  font-size: 20px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
