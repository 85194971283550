const COLOURS = {
  INCENTIVE: { background: '#6CACE4', text: '#FFFFFF' },
  CC: { background: '#FFCE99', text: '#000000' },
  RENTAL_SUBSIDY: { background: '#e6e6e6', text: '#000000' },
  VALUE: { text: '#495054' },
};

const SIZES = {
  BANNER: { MINIMISED: 60, DESKTOP: 118, MOBILE: 160 },
  BANNER_ICON: { DESKTOP: '75px', MOBILE: '30px' },
  SEARCH_RESULTS_HEIGHT: {},
};

export { COLOURS, SIZES };
