<template>
  <ComparisonTable />
</template>
<script>
import ComparisonTable from '../../components/ComparisonTable';
export default {
  name: 'MapView',
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  components: {
    ComparisonTable,
  },
};
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

.ads-link {
  color: $ads-red-2-dark !important;
}

.info-window {
  position: absolute;
  width: 1000px;
  height: 80%;
  top: 15px;
  left: 0px;
}
</style>
