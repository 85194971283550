<template>
  <v-container fluid style="height: -webkit-calc(100vh - 118px); overflow-y: auto; background-color: #eaeaea">
    <v-row class="ma-0">
      <v-col class="pa-0" :class="mobile ? 'ml-1 mt-2' : 'ml-10 pl-4 mt-5'">
        <AdsButton @click="goBack" tertiary class="backbutton pa-0 primary--text">
          <v-icon class="mr-1">arrow_back</v-icon>
          Back
        </AdsButton>
      </v-col>
    </v-row>
    <v-row class="ma-0" style="width: 100%">
      <v-col class="mt-0" :class="mobile ? 'ma-1' : 'ma-10'">
        <Dialog
          :openDialog="showAllSchoolDialog"
          title="Add school"
          maxWidth="70%"
          :fullscreen="mobile"
          icon="add_circle_outline"
          :displayCloseBtn="true"
          @close="showAllSchoolDialog = false"
          @click:outside="showAllSchoolDialog = false"
        >
          <template slot="text">
            <div class="pa-0">
              <CompareSchool @compareSchool="addSelectedSchool" :results="schoolResults" />
            </div>
          </template>
        </Dialog>
        <div class="d-flex justify-space-between align-end mb-8">
          <div>
            <h1 class="display-1">Compare</h1>
            <p class="mb-0">
              Compare the range of benefits and incentives across NSW public schools. You may compare up to 5 schools.
            </p>
          </div>
          <div>
            <AdsButton secondary @click="trackEventDownload">
              <v-icon class="mt-1 pr-1 material-icons-outlined"> download </v-icon>
              <download-csv :data="csvData">Download CSV</download-csv>
            </AdsButton>
          </div>
        </div>
        <div style="overflow-x: auto">
          <table>
            <thead>
              <tr>
                <th class="px-6 py-8 schoolTitle" style="background-color: #041e42; color: #6cace4">Schools</th>
                <th
                  v-for="school in schools"
                  :key="school.schoolCode"
                  class="px-4 py-8 title"
                  style="background-color: #041e42; color: white"
                >
                  <div class="d-flex justify-space-between">
                    <div class="pr-2">{{ school.schoolFullName }}</div>
                    <div class="removeSchool">
                      <AdsButton
                        primary
                        style="background-color: #041e42; color: #6cace4; border-color: #6cace4"
                        button-text=""
                        icon="remove_circle_outline"
                        @click="remoteFromCompare(school)"
                      ></AdsButton>
                    </div>
                  </div>
                </th>
                <th
                  v-for="col in freeCols"
                  :key="col"
                  class="px-4 py-8 title"
                  style="background-color: #041e42; color: #c8dcf0"
                >
                  <div style="width: 100%; text-align: center">
                    <AdsButton
                      secondary
                      dark
                      style="background-color: #041e42; color: #6cace4 !important; border-color: #6cace4"
                      button-text="Add School"
                      icon="add_circle_outline"
                      @click="addSchoolForComparison()"
                    ></AdsButton>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-for="(benefitList, category, index) in allBenefits" :key="category">
              <tr>
                <td colspan="100%" class="title" style="background-color: #eaeaea; border-bottom: 1px solid #ccc">
                  <AdsExpansionPanel
                    v-model="expansionPanelModel[index]"
                    :items="[
                      {
                        title: convertCamelCaseToCapitalizedWithSpaces(category),
                      },
                    ]"
                  >
                    <template #content1>
                      <div>
                        <div class="d-flex" v-for="benefit in benefitList" :key="benefit">
                          <div class="pa-6" style="width: 16.66%; border-right: 1px solid #ccc">
                            <div class="benefitsTitle mb-1">{{ benefit }}</div>
                            <div v-if="findExtraLine(benefit).length > 0" class="body-2" style="color: #495054">
                              {{ findExtraLine(benefit)[0] }}
                            </div>
                          </div>
                          <div
                            v-for="school in schools"
                            :key="school.schoolCode + 'benefit'"
                            class="pa-6"
                            style="width: 16.66%; border-right: 1px solid #ccc"
                          >
                            <div class="d-flex">
                              <div class="mr-4">
                                <div v-if="findBenefitValue(school, benefit).isIncluded === true">
                                  <v-icon class="green-tick">check</v-icon>
                                </div>
                                <div v-else-if="findBenefitValue(school, benefit).isIncluded === false">
                                  <v-icon class="red-cross">close</v-icon>
                                </div>
                              </div>
                              <div>
                                <div
                                  v-if="findBenefitValue(school, benefit).value"
                                  class="subtitle-1"
                                  style="color: #22272b"
                                >
                                  {{ findBenefitValue(school, benefit).value }}
                                </div>
                                <div class="body-1" style="color: #22272b" v-else>
                                  <v-icon class="red-cross">close</v-icon>
                                  N/A
                                </div>
                                <div class="body-2" style="color: #495054">
                                  {{ findBenefitValue(school, benefit).extraValueLine }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </AdsExpansionPanel>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import CompareSchool from '@/components/SearchResults/CompareSchool';
import { AdsExpansionPanel, Dialog, AdsButton } from '@nswdoe/doe-ui-core';
export default {
  components: {
    AdsExpansionPanel,
    Dialog,
    AdsButton,
    CompareSchool,
  },
  mounted() {
    if (!this.schoolResults || this.schoolResults.length == 0) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      expansionPanelModel: [0, null, null, null, null],
      showAllSchoolDialog: false,
      scrollerHeight: '90vh',
    };
  },
  computed: {
    ...mapGetters({
      results: 'moduleResults/results',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    schoolResults() {
      if (Array.isArray(this.results) && this.results.length > 0) {
        return this.results;
      }
      return [];
    },
    schools() {
      return this.$store.state.compareList;
    },
    csvData() {
      // First, get all unique benefits across all schools
      let uniqueBenefits = [
        ...new Set(
          this.schools.flatMap((school) =>
            this.standardiseBenefitsForCSV(school.benefitsAndAllowances).map((benefit) => benefit.benefit)
          )
        ),
      ];
      // Now create an array for each benefit, with each school's value for that benefit
      let csvData = uniqueBenefits.map((benefitName) => {
        let row = { benefit: benefitName };

        this.schools.forEach((school) => {
          let schoolBenefit = this.standardiseBenefitsForCSV(school.benefitsAndAllowances).find(
            (benefit) => benefit.benefit === benefitName
          );

          row[school.schoolFullName] = schoolBenefit ? schoolBenefit.value : null;
        });
        return row;
      });

      return csvData;
    },
    freeCols() {
      return 5 - this.schools.length;
    },
    allBenefits() {
      let benefits = {};
      this.schools.forEach((school) => {
        Object.entries(school.benefitsAndAllowances).forEach(([categoryKey, category]) => {
          category.benefits.forEach((benefit) => {
            if (!benefits[categoryKey]) {
              benefits[categoryKey] = [];
            }
            if (!benefits[categoryKey].includes(benefit.benefit)) {
              benefits[categoryKey].push(benefit.benefit);
            }
          });
        });
      });
      return benefits;
    },
  },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    remoteFromCompare(school) {
      this.$gtm.trackEvent({
        event: 'school_removed_from_compare',
        item_category: 'school',
        action: 'compare_remove_click',
        school_name: school.schoolFullName,
        school_code: school.schoolCode,
      });

      this.$store.dispatch('removeFromCompare', school);
    },
    addSchoolForComparison() {
      this.showAllSchoolDialog = true;
    },
    trackEventDownload() {
      this.$gtm.trackEvent({
        event: 'gtm.linkClick',
        action: 'download_csv_clicked',
      });
    },
    addSelectedSchool() {
      this.showAllSchoolDialog = false;
    },
    findBenefitValue(school, benefitName) {
      let result = {
        value: null,
        extraValueLine: '',
        isIncluded: null,
      };
      Object.values(school.benefitsAndAllowances).forEach((category) => {
        category.benefits.forEach((benefit) => {
          if (benefit.benefit === benefitName) {
            result.value = typeof benefit.value === 'number' ? this.formatCurrency(benefit.value) : benefit.value;
            result.extraValueLine = benefit.extraValueLine || '';
            result.isIncluded = Object.prototype.hasOwnProperty.call(benefit, 'isIncluded') ? benefit.isIncluded : null;
          }
        });
      });
      return result;
    },
    findExtraLine(benefitName) {
      let extraLine = [];
      this.schools.forEach((school) => {
        Object.values(school.benefitsAndAllowances).forEach((category) => {
          category.benefits.forEach((benefit) => {
            if (benefit.benefit === benefitName) {
              extraLine.push(benefit.extraLine || '');
            }
          });
        });
      });
      return extraLine;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        maximumFractionDigits: 0,
        currency: 'AUD',
      }).format(value);
    },
    convertCamelCaseToCapitalizedWithSpaces(str) {
      let words = str
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ');

      words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      return words.join(' ');
    },
    standardiseBenefitsForCSV(data) {
      let benefitsArray = [];

      for (let category in data) {
        for (let benefit of data[category].benefits) {
          let newBenefit = {};

          // Combine benefit and extraLine values
          if (benefit.extraLine) {
            newBenefit.benefit = `${benefit.benefit} - ${benefit.extraLine}`;
          } else {
            newBenefit.benefit = benefit.benefit;
          }

          // Fill in other values, or null if not provided
          newBenefit.isIncluded = Object.prototype.hasOwnProperty.call(benefit, 'isIncluded')
            ? benefit.isIncluded
              ? 'Yes'
              : 'No'
            : null;

          if (Object.prototype.hasOwnProperty.call(benefit, 'value')) {
            if (typeof benefit.value === 'number') {
              newBenefit.value = new Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: 'AUD',
              }).format(benefit.value);
            } else {
              newBenefit.value = benefit.value;
            }
          } else {
            newBenefit.value = null;
          }

          newBenefit.extraValueLine = Object.prototype.hasOwnProperty.call(benefit, 'extraValueLine')
            ? benefit.extraValueLine
            : null;

          benefitsArray.push(newBenefit);
        }
      }

      return benefitsArray;
    },
  },
};
</script>

<style scoped>
.removeSchool ::v-deep .v-btn:not(.v-btn--round).v-size--default {
  min-width: 24px !important;
  width: 24px !important;
  padding: 0 !important;
}
.removeSchool ::v-deep .pl-2 {
  padding-left: 0 !important;
}
::v-deep .subtitle-1 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}
.schoolTitle {
  font-size: 24px !important;
}
.backbutton::v-deep.v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
}
.benefitsTitle {
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #22272b;
}
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: white;
}

th,
td {
  text-align: left;
  border-right: 1px solid #ccc;
  width: 16.66%;
}

td {
  vertical-align: top;
}

.green-tick {
  color: green;
}

.red-cross {
  color: red;
}
</style>
