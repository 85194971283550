<template>
  <v-card class="pt-0 d-flex" flat>
    <div
      style="width: 100%; overflow: hidden"
      :style="{ height: mobile ? '90vh' : '63vh' }"
      class="d-flex flex-column mt-0"
    >
      <div ref="fieldsElement">
        <v-row :class="mobile ? 'pa-2' : ''" no-gutters>
          <v-col class="pa-4">
            <AdsTextField
              v-model="filters.searchValue"
              placeholder="Search school or region"
              prepend-inner-icon="search"
              @blur="
                () => {
                  updateFilters();
                  emitAnalytics('search', filters.searchValue);
                }
              "
              label="Search school or region"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <div>
          <div v-if="!mobile" class="d-flex flex-wrap px-5">
            <ChipFilter
              key="filter_1"
              v-model="benefitsModal"
              :items="typeBenefits"
              heading="Benefits"
              name="Benefits"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
            <ChipFilter
              key="filter_2"
              v-model="levelModal"
              :items="schoolLevel"
              heading="School level"
              name="School level"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
            <ChipFilter
              key="filter_3"
              v-model="sizeModal"
              :items="sizeSchool"
              heading="School size"
              name="School size"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
            <ChipFilter
              key="filter_4"
              v-model="pointsModal"
              :items="typePoints"
              heading="Transfer points"
              name="Transfer points"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
            <ChipFilter
              key="filter_5"
              v-model="allowancesModal"
              :items="typeAllowances"
              heading="Allowances"
              name="Allowances"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
            <ChipFilter
              key="filter_6"
              v-model="typeModal"
              :items="schoolType"
              heading="School type"
              name="School type"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="updateFilters"
              class="pa-1"
            />
          </div>
          <v-slide-group v-else-if="mobile" :show-arrows="false" class="ma-2 d-flex flex-wrap" hide-delimiters>
            <v-slide-item key="slide_1">
              <ChipFilter
                key="filter_1"
                v-model="benefitsModal"
                :items="typeBenefits"
                heading="Benefits"
                name="Benefits"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_2">
              <ChipFilter
                key="filter_2"
                v-model="levelModal"
                :items="schoolLevel"
                heading="School type"
                name="School type"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_3">
              <ChipFilter
                key="filter_3"
                v-model="sizeModal"
                :items="sizeSchool"
                heading="School size"
                name="School size"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_4">
              <ChipFilter
                key="filter_4"
                v-model="pointsModal"
                :items="typePoints"
                heading="Transfer points"
                name="Transfer points"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_5">
              <ChipFilter
                key="filter_5"
                v-model="allowancesModal"
                :items="typeAllowances"
                heading="Allowances"
                name="Allowances"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
            <v-slide-item key="slide_6">
              <ChipFilter
                key="filter_6"
                v-model="typeModal"
                :items="schoolType"
                heading="School Status"
                name="School Status"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="updateFilters"
              />
            </v-slide-item>
          </v-slide-group>
          <div v-if="schoolResults.length > 0" class="px-6 pt-6 pb-4">
            <div class="d-flex justify-space-between align-end">
              <div class="default-text font-weight-bold">{{ schoolResults.length }} total results</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isUserSearch"
        class="pa-0 pt-0 pb-0"
        :style="`border-top: 1px solid #ccc ${mobile ? 'width: 100vw' : ''}`"
      >
        <VirtualScroller
          :inFullScreen="true"
          :height="scrollerHeight.toString()"
          @compareSchool="compareSchool"
          contentWidth="100%"
          :results="schoolResults"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import { ChipFilter, AdsTextField } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
import { COLOURS, SIZES } from '@/constants';
import VirtualScroller from '@/components/SearchResults/VirtualScroller';
import { ref } from 'vue';
import { useElementSize } from '@vueuse/core';

export default {
  name: 'AddSchool',
  components: { AdsTextField, ChipFilter, VirtualScroller },
  props: {
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      COLOURS: COLOURS,
      SIZES: SIZES,
      tab: null,
      menu: false,
      scrollerHeight: '0',
      tabBarHeight: '0',
      benefitsModal: [],
      filters: {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      },
      sizeModal: [],
      typeModal: [],
      allowancesModal: [],
      levelModal: [],
      pointsModal: [],
      searchModal: '',
      clientHeight: document.documentElement.clientHeight,
      NAV_ITEMS: [{ tab: 'LIST VIEW' }, { tab: 'MAP VIEW' }],
      schoolLevel: [
        { id: 'P', name: 'Primary school' },
        { id: 'S', name: 'High school' },
        { id: 'C', name: 'Comprehensive school' },
      ],
      sizeSchool: [
        { id: 100, name: 'Up to 100 enrolments' },
        { id: 200, name: 'Up to 200 enrolments' },
        { id: 300, name: 'Up to 300 enrolments' },
        { id: 400, name: 'Up to 400 enrolments' },
        { id: 500, name: 'Up to 500 enrolments' },
        { id: 501, name: '500+ enrolments' },
      ],
      typeWork: [
        { id: '1', name: 'Casual' },
        {
          id: '2',
          name: 'Permanent',
        },
        {
          id: '3',
          name: 'Temporary',
        },
      ],
      typePoints: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 4, name: '4' },
        { id: 6, name: '6' },
        { id: 8, name: '8' },
      ],
      typeBenefits: [
        {
          id: 'Rural teacher incentive',
          name: 'Rural teacher incentive',
        },
        { id: 'Rental subsidy', name: 'Rental subsidy' },
        { id: 'Retention benefit', name: 'Retention benefit' },
        {
          id: 'Experienced teacher benefit',
          name: 'Experienced teacher benefit',
        },
        { id: 'Recruitment bonus', name: 'Recruitment bonus' },
      ],
      typeAllowances: [
        {
          id: 'Broken Hill Allowance',
          name: 'Broken Hill Allowance',
        },
        {
          id: 'Hot Climatic allowance',
          name: 'Hot Climatic Allowance',
        },
        {
          id: 'Cold Climatic allowance',
          name: 'Cold Climatic Allowance',
        },
        {
          id: 'Country Area Program',
          name: 'Country area program',
        },
        {
          id: 'Isolation from goods and service allowance',
          name: 'Isolation from goods and service allowance',
        },
        {
          id: 'Medical and Dental re-imbursement',
          name: 'Medical and Dental re-imbursement',
        },
        {
          id: 'Motor Vehicle Depreciation Allowance',
          name: 'Motor Vehicle Depreciation Allowance',
        },
        {
          id: 'Relocation Subsidy',
          name: 'Relocation Subsidy',
        },
        {
          id: 'Vacation travel expense (no of vacation journeys)',
          name: 'Vacation travel expense',
        },
      ],
      schoolType: [
        {
          id: 'Incentive school',
          name: 'Incentive school',
        },
        {
          id: 'Connected community school',
          name: 'Connected community school',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isUserSearch: 'isUserSearch',
      numberOfCompare: 'numberOfCompare',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    schoolResults() {
      let results = this.results;
      const filters = this.filters;
      let usedFilters = [];
      if (filters.searchValue) {
        const filterRegion = filters.searchValue.toLowerCase();
        usedFilters.push('Search_Region');
        results = results.filter(
          (result) =>
            result.address.toLowerCase().indexOf(filterRegion) != -1 ||
            result.schoolFullName.toLowerCase().includes(filterRegion) ||
            result.regionName.toLowerCase().includes(filterRegion)
        );
      }
      if (filters.selectedPoints.length) {
        usedFilters.push('Transfer_points');
        results = results.filter((result) => filters.selectedPoints.includes(result.transferPoints));
      }
      if (filters.selectedLevel.length) {
        usedFilters.push('School_level');
        results = results.filter((result) => filters.selectedLevel.includes(result.schoolLevel));
      }
      if (filters.selectedSize.length) {
        usedFilters.push('School_size');
        const sortedSize = filters.selectedSize.sort((a, b) => b - a)[0];
        if (sortedSize > 500) {
          results = results.filter((result) => result.studentNumber > 500);
        } else results = results.filter((result) => result.studentNumber <= sortedSize);
      }
      if (filters.selectedAllowance.length) {
        usedFilters.push('Allowances');
        const lowerCaseSelectedAllowance = filters.selectedAllowance.map((item) => item.toLowerCase());
        results = results.filter((result) => {
          return (
            result.benefitsAndAllowances.conditionalAllowances.benefits.some(
              (item) => lowerCaseSelectedAllowance.indexOf(item.benefit.toLowerCase()) != -1
            ) ||
            result.benefitsAndAllowances.otherBenefits.benefits.some(
              (item) => lowerCaseSelectedAllowance.indexOf(item.benefit.toLowerCase()) != -1
            )
          );
        });
      }
      if (filters.selectedBenefits.length) {
        const lowerCaseSelectedBenefits = filters.selectedBenefits.map((item) => item.toLowerCase());
        results = results.filter((result) => {
          return result.benefitsAndAllowances.majorFinancialBenefits.benefits.some(
            (item) => lowerCaseSelectedBenefits.indexOf(item.benefit.toLowerCase()) != -1 && item.isIncluded
          );
        });
        usedFilters.push('Benefits');
      }
      if (filters.isIncentiveOnly) {
        results = results.filter((result) => result.isIncentive === true);
        usedFilters.push('School_type');
      }
      if (filters.isConnectedCommunityOnly) {
        usedFilters.push('School_type');
        results = results.filter((result) => result.connectedCommunitySchool === 'true');
      }
      if (filters.selectedType.length) {
        usedFilters.push('School_type');
        const isIncentiveSchool = filters.selectedType.some((item) => item.toLowerCase() === 'incentive school');
        const isConnectedCommunitySchool = filters.selectedType.some(
          (item) => item.toLowerCase() === 'connected community school'
        );
        results = results.filter(
          (result) =>
            (result.isIncentive === true && isIncentiveSchool) ||
            (result.connectedCommunitySchool === 'true' && isConnectedCommunitySchool)
        );
      }
      this.$gtm.trackEvent({
        event: 'school_list_viewed',
        item_category: 'school',
        action: 'list_displayed_after_filter',
        filters_used: usedFilters && usedFilters.length > 0 ? [...new Set(usedFilters)].join(',') : '',
      });
      return results;
    },
  },
  created() {
    this.resetFilters();
  },
  watch: {
    benefitsModal(value, oldValue) {
      if (oldValue != null && value != oldValue) {
        this.filters.selectedBenefits = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedBenefits');
      }
    },
    levelModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedLevel = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedLevel');
      }
    },
    sizeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedSize = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedSize');
      }
    },
    pointsModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedPoints = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedPoints');
      }
    },
    allowancesModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedAllowance = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedAllowance');
      }
    },
    typeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedType = value;
        this.updateFilters();
        this.emitAnalytics('filter', 'selectedType');
      }
    },
    fieldsHeight(val) {
      this.scrollerHeight = document.documentElement.clientHeight - this.getScrollerHeight(val);
    },
    tabNavHeight(val) {
      if (!this.tabBarHeight) {
        this.tabBarHeight = (document.documentElement.clientHeight + val).toString();
        this.$emit('scrollHeight', this.tabBarHeight);
      }
    },
  },
  methods: {
    trackGTMevent(label) {
      this.$gtm.trackEvent({
        event: 'mobile_view_switched',
        item_category: 'mobile_view',
        view_type: label,
      });
    },
    compareSchool() {
      this.$emit('compareSchool');
    },
    emitAnalytics(type, value) {
      let gtmOptions = {
        event: '',
        item_category: '',
        action: '',
        'target-properties': '',
      };

      switch (type) {
        case 'filter':
          gtmOptions.event = 'filter_selected';
          gtmOptions.item_category = 'results_filter';
          gtmOptions.action = 'dropdown_select';
          gtmOptions['target-properties'] = null;
          gtmOptions = {
            ...gtmOptions,
            ...{
              filter_type: this.getFilterName(value),
              'target-properties': this.filters[value].join(','),
            },
          };
          break;
        case 'search':
          gtmOptions.event = 'filter_selected';
          gtmOptions.item_category = 'results_filter';
          gtmOptions.action = 'textbox_value';
          gtmOptions['target-properties'] = 'School or Region';
          break;
      }
      this.$gtm.trackEvent(gtmOptions);
    },
    getFilterName(filterKey) {
      const filtersOptions = [
        { key: 'selectedBenefits', value: 'Benefits' },
        { key: 'selectedLevel', value: 'school_level' },
        { key: 'selectedSize', value: 'school_size' },
        { key: 'selectedPoints', value: 'transfer_points' },
        { key: 'selectedAllowance', value: 'allowances' },
        { key: 'selectedType', value: 'school_type' },
      ];
      return filtersOptions.find((option) => option.key == filterKey).value;
    },
    updateFilters() {
      this.$store.commit('SET_IS_LOADING', true);
      this.$store.dispatch('moduleResults/updateFilters', this.filters);
      this.$store.commit('SET_IS_LOADING', false);
    },
    resetFilters() {
      this.$store.dispatch('moduleResults/updateFilters', {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      });
    },
    showComparisonTableAction() {
      this.$router.push('/comparison');
    },
    closeMenu() {
      this.menu = false;
    },
    checkForBanner() {
      return this.mobile && this.isUserSearch;
    },
    getScrollerHeight(elHeight) {
      let bannerHeight = 300;
      if (this.mobile) {
        bannerHeight = 30;
      }
      const total = bannerHeight + elHeight;
      return total;
    },
  },
  emits: ['scrollHeight', 'tabHeight'],
  setup() {
    const fieldsElement = ref(null);
    const tabNavElement = ref(null);
    let fieldsWidth = 0;
    let fieldsHeight = 0;
    let tabNavWidth = 0;
    let tabNavHeight = 0;
    const setFields = () => {
      const { width, height } = useElementSize(fieldsElement);
      fieldsWidth = width;
      fieldsHeight = height;
    };
    const setTabNav = () => {
      const { width, height } = useElementSize(tabNavElement);
      tabNavWidth = width;
      tabNavHeight = height;
    };
    setFields();
    setTabNav();
    return {
      fieldsElement,
      tabNavElement,
      fieldsWidth,
      fieldsHeight,
      tabNavWidth,
      tabNavHeight,
    };
  },
};
</script>
<style scoped>
.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 13px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
